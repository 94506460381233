import React, {useRef } from 'react'
import { gsap } from 'gsap'

const Digital = () => {
  const refDigitalSVG = useRef()
  const refScreen01 = useRef()
  const refScreen02 = useRef()
  const refScreen03 = useRef()
  const refScreen04 = useRef()

  const getRange = (percent, min, max) => {
    return min + (max - min) * percent / 100;
  }

  const handleMove = (e) => {
    let xPos = (e.clientX - (refDigitalSVG.current.getBoundingClientRect().left)) / refDigitalSVG.current.getBoundingClientRect().width
    let yPos = (e.clientY - (refDigitalSVG.current.getBoundingClientRect().top)) / refDigitalSVG.current.getBoundingClientRect().height
    gsap.to(refScreen04.current, {
      x: getRange(xPos * 100, 10, -10),
      y: getRange(yPos * 100, 20, -20),
      ease: 'power1.out'
    })
    gsap.to(refScreen03.current, {
      x: getRange(xPos * 100, 5, -5),
      y: getRange(yPos * 100, 10, -10),
      ease: 'power1.out'
    })
    gsap.to(refScreen02.current, {
      x: getRange(xPos * 100, -5, 5),
      y: getRange(yPos * 100, -10, 10),
      ease: 'power1.out'
    })
    gsap.to(refScreen01.current, {
      x: getRange(xPos * 100, -10, 10),
      y: getRange(yPos * 100, -20, 20),
      ease: 'power1.out'
    })
  }

  const handleOut = () => {
    gsap.to([refScreen04.current, refScreen03.current, refScreen02.current, refScreen01.current], {
      x: 0,
      y: 0
    })
  }

  return (
    <svg
      ref={refDigitalSVG}
      style={{overflow: 'visible'}}
      className="svgDigital"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400 400"
      onMouseMove={(e) => {handleMove(e)}}
      onMouseOut={handleOut}
    >
      <defs>
        <linearGradient id="digitalGradient" gradientTransform="rotate(30)">
          <stop offset="5%" stopColor="var(--alpha)"></stop>
          <stop offset="95%" stopColor="var(--bravo)"></stop>
        </linearGradient>
        <linearGradient id="digitalGradientFlipped" gradientTransform="rotate(30)">
          <stop offset="5%" stopColor="var(--bravo)"></stop>
          <stop offset="95%" stopColor="var(--alpha)"></stop>
        </linearGradient>
        <clipPath id="SVGID_2_5">
          <rect id="SVGID_1_5" x="49.4" y="71.9" width="350.6" height="197.2"></rect>
        </clipPath>
        <clipPath id="SVGID_4_5">
          <rect id="SVGID_3_5" x="49.4" y="71.9" width="350.6" height="197.2"></rect>
        </clipPath>
        <clipPath id="SVGID_7_5">
          <rect id="SVGID_6_5" x="32.9" y="168.3" width="209.5" height="134.8"></rect>
        </clipPath>
        <clipPath id="SVGID_9_5">
          <polygon id="SVGID_8_5" points="242.4,178.4 32.9,168.3 32.9,292.9 242.4,303.1"></polygon>
        </clipPath>
        <clipPath id="SVGID_12_5">
          <rect id="SVGID_11_5" x="16.5" y="194" width="70.2" height="121.4"></rect>
        </clipPath>
        <clipPath id="SVGID_14_5">
          <polygon id="SVGID_13_5" points="86.7,315.4 86.7,204.1 16.5,194 16.5,305.2"></polygon>
        </clipPath>
        <clipPath id="SVGID_17_5">
          <polygon id="SVGID_16_5" points="22.5,322.8 22.5,250.3 0,240.2 0,312.7"></polygon>
        </clipPath>
      </defs>
      <g id="screen04" ref={refScreen04}>
        <g clipPath="url(#SVGID_2_5)">
          <polygon clipPath="url(#SVGID_4_5)" fill="url(#digitalGradient)" points="226.9,-103.4 498.6,171.8 222.5,444.4 -49.2,169.3"></polygon>
        </g>
        <path clipPath="url(#SVGID_2_5)" fill="#FFFFFF" d="M382,92.7l-0.1,13.6l6.2-11.4L382,92.7z M381.6,92.7l-6.1,2.2l6.1,11.4L381.6,92.7z M390.4,84.4l-8.2,7.9
          l6.1,2.2L390.4,84.4z M375,94.8l-2.3-11.7l9.1,8.9l9.3-9l-2.4,11.8l-7,12.8L375,94.8z M381.4,92.2l-8-7.8l2,9.9L381.4,92.2z"></path>
      </g>
      <g id="screen03" ref={refScreen03}>
        <defs>
        </defs>
        <g clipPath="url(#SVGID_7_5)">
          <polygon clipPath="url(#SVGID_9_5)" fill="url(#digitalGradientFlipped)" points="139,63.6 309.8,236.5 136.3,407.8 -34.4,234.8"></polygon>
        </g>
      </g>
      <g id="screen02" ref={refScreen02}>
        <g clipPath="url(#SVGID_12_5)">
          <polygon clipPath="url(#SVGID_14_5)" fill="url(#digitalGradient)" points="52,158.9 147.4,255.5 51.1,350.5 -44.2,253.9"></polygon>
        </g>
      </g>
      <g id="screen01" ref={refScreen01}>
        <polygon clipPath="url(#SVGID_17_5)" fill="url(#digitalGradientFlipped)" points="11.4,229 63.8,282 11.1,334 -41.3,281 	"></polygon>
      </g>
      <g id="tree_x5F_left">
        <rect x="101" y="318.2" fill="#9E9FA0" width="5.5" height="9.9"></rect>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="106.5" y1="320.8" x2="103.7" y2="320"></line>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="101" y1="320.8" x2="103.7" y2="320"></line>
        <polygon fill="#ABADAE" points="103.7,302.7 113.9,306.4 124.8,325.7 103.7,320 	"></polygon>
        <polygon fill="#A2A4A5" points="103.7,302.7 93.5,306.4 82.6,325.7 103.7,320 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="113.9" y1="306.4" x2="103.7" y2="302.7"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="103.7" y1="302.7" x2="93.5" y2="306.4"></line>
        <polygon fill="#B5B7B7" points="103.7,284.8 112.8,288.1 121.3,309 103.7,302.7 	"></polygon>
        <polygon fill="#A2A4A5" points="103.7,284.8 94.7,288.1 86.1,309 103.7,302.7 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="103.7" y1="284.8" x2="112.8" y2="288.1"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="103.7" y1="284.8" x2="94.7" y2="288.1"></line>
        <polygon fill="#CCCCCC" points="103.7,257.9 116.6,289.5 103.7,284.8 	"></polygon>
        <polygon fill="#A7A8A9" points="103.7,257.9 90.9,289.5 103.7,284.8 	"></polygon>
      </g>
      <g id="trees_x5F_right">
        <rect x="357.4" y="276.5" fill="#9E9FA0" width="9.1" height="16.3"></rect>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="366.4" y1="280.7" x2="361.9" y2="279.5"></line>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="357.4" y1="280.7" x2="361.9" y2="279.5"></line>
        <polygon fill="#ABADAE" points="361.9,251.1 378.7,257 396.6,288.9 361.9,279.5 	"></polygon>
        <polygon fill="#A2A4A5" points="361.9,251.1 345.1,257 327.2,288.9 361.9,279.5 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="378.7" y1="257" x2="361.9" y2="251.1"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.9" y1="251.1" x2="345.1" y2="257"></line>
        <polygon fill="#B5B7B7" points="361.9,221.5 376.8,227 390.9,261.4 361.9,251.1 	"></polygon>
        <polygon fill="#A2A4A5" points="361.9,221.5 347,227 332.9,261.4 361.9,251.1 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.9" y1="221.5" x2="376.8" y2="227"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.9" y1="221.5" x2="347" y2="227"></line>
        <polygon fill="#CCCCCC" points="361.9,177.3 383.1,229.3 361.9,221.5 	"></polygon>
        <polygon fill="#A7A8A9" points="361.9,177.3 340.7,229.3 361.9,221.5 	"></polygon>
        <rect x="325.3" y="293" fill="#9E9FA0" width="7.8" height="14"></rect>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="333.1" y1="296.7" x2="329.2" y2="295.6"></line>
        <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="325.3" y1="296.7" x2="329.2" y2="295.6"></line>
        <polygon fill="#ABADAE" points="329.2,271.3 343.6,276.4 358.9,303.7 329.2,295.6 	"></polygon>
        <polygon fill="#A2A4A5" points="329.2,271.3 314.8,276.4 299.5,303.7 329.2,295.6 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="343.6" y1="276.4" x2="329.2" y2="271.3"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="329.2" y1="271.3" x2="314.8" y2="276.4"></line>
        <polygon fill="#B5B7B7" points="329.2,245.9 342,250.6 354.1,280.1 329.2,271.3 	"></polygon>
        <polygon fill="#A2A4A5" points="329.2,245.9 316.4,250.6 304.3,280.1 329.2,271.3 	"></polygon>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="329.2" y1="245.9" x2="342" y2="250.6"></line>
        <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="329.2" y1="245.9" x2="316.4" y2="250.6"></line>
        <polygon fill="#CCCCCC" points="329.2,208.1 347.3,252.6 329.2,245.9 	"></polygon>
        <polygon fill="#A7A8A9" points="329.2,208.1 311.1,252.6 329.2,245.9 	"></polygon>
      </g>
    </svg>
  )
}

export default Digital