import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import hexagons from '../images/hexagon.svg'
import {gsap} from 'gsap'

const RipplingAK = () => {

useEffect(() => {
  const tlAk = gsap.timeline({ repeat: -1 })
  tlAk.to('.boxes .box', {
    duration: 1,
    scale: 0.1,
    ease: 'power1.inOut',
    stagger: {
      amount: 1.5,
      grid: [20, 25],
      from: [0.55, 0.6],
    },
  })
  tlAk.to(
    '.boxes .box',
    {
      duration: 1,
      scale: 1,
      ease: 'power1.inOut',
      stagger: {
        amount: 1.5,
        grid: [20, 25],
        from: [0.55, 0.6],
      },
    },
    '-=1.5'
  )
}, [])
  
  return (
    <Box
      className="boxes"
      position="absolute"
      right={['50%', null, null, 0]}
      top="50%"
      transform={['translate(50%, -50%) scale(0.8) ', null, null, 'translateY(-50%) scale(0.8)']}
      width="655px"
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(20px, 1fr))"
      gridGap="6px"
      sx={{
        '.box': {
          width: "20px",
          height: "20px",
          backgroundImage: `url(${hexagons})`,
          backgroundSize: "cover",
          opacity: ['0.1', null, null, '0.4'],
          '&.empty': {
            backgroundColor: "transparent",
            backgroundImage: "none"
          }
        }
      }}
    >
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
      <div className="box empty"></div>
    </Box>
  )
}

export default RipplingAK