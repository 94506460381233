import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const Marketing = () => {
  const refMarketingSVG = useRef()
  const refFlags = useRef()
  const refFlag01 = useRef()
  const refLogo = useRef()
  const refPeak = useRef()

  useEffect(() => {
    // ScrollTrigger
    gsap.from(refFlags.current, {
      scrollTrigger: {
        trigger: refMarketingSVG.current,
        start: 'top 75%',
        end: 'top 20%',
        scrub: true,
        // marker: true
      },
      y: 75,
    })

    // Flag waving
    gsap.set(refFlag01.current, {attr: {d: 'M141.1,37.8c23.5-11.6,87.6-43.9,120.5-36.9c3.2,17,20.7,109.3,22.9,120.2c-51.4-12-82,3-125.1,21.6C153.2,107.4,142.9,48.6,141.1,37.8z'}})
    const tl = gsap.timeline({repeat: -1, ease: 'none'})
    tl.to(refFlag01.current, {
      attr: {
        d: 'M141.1,37.8c28.8,2.8,85.4-42.5,120.5-36.9c3.2,17,20.7,109.3,22.9,120.2c-51-9.9-77.3,22.8-125.1,21.6C153.2,107.4,142.9,48.6,141.1,37.8z'
      },
      duration: 0.5
    })
    tl.to(refFlag01.current, {
      attr: {
        d: 'M141.1,37.8c28.8,2.8,86-35.9,120.9-35c3.2,17,20.3,108.8,22.5,119.7c-50.1-3.2-77.3,21.4-125.1,20.2C153.2,107.4,142.9,48.6,141.1,37.8z'
      },
      duration: 0.5
    })
    tl.to(refFlag01.current, {
      attr: {
        d: 'M141.1,37.8c27.9-22,90.6-13.5,120.5-33.3c3.2,17,20.7,108.3,22.9,119.2c-43.3,16.2-79.7-5.5-125.1,18.9C153.2,107.4,142.9,48.6,141.1,37.8z'
      },
      duration: 0.5
    })
    tl.to(refFlag01.current, {
      attr: {
        d: 'M141.1,37.8c23.5-11.6,87.6-43.9,120.5-36.9c3.2,17,20.7,109.3,22.9,120.2c-51.4-12-82,3-125.1,21.6C153.2,107.4,142.9,48.6,141.1,37.8z'
      },
      duration: 0.5
    })
    gsap.to(refLogo.current, {
      yoyo: true,
      repeat: -1,
      duration: 1,
      x: 2,
      y: 6
    })
  }, [])

  // Mouse parallax
  const getRange = (percent, min, max) => {
    return min + (max - min) * percent / 100;
  }

  const handleMove = (e) => {
    let xPos = (e.clientX - (refMarketingSVG.current.getBoundingClientRect().left)) / refMarketingSVG.current.getBoundingClientRect().width
    let yPos = (e.clientY - (refMarketingSVG.current.getBoundingClientRect().top)) / refMarketingSVG.current.getBoundingClientRect().height
    gsap.to(refFlags.current, {
      x: getRange(xPos * 100, -5, 5),
      y: getRange(yPos * 100, 18, -18),
      ease: 'power1.out'
    })
    gsap.to(refPeak.current, {
      x: getRange(xPos * 100, 5, -5),
      y: getRange(yPos * 100, -10, 10),
      ease: 'power1.out'
    })
  }

  const handleOut = () => {
    gsap.to(refFlags.current, {
      y: 0
    })
  }

  return (
    <svg
      ref={refMarketingSVG}
      style={{overflow: 'visible'}}
      version="1.1"
      className="svgMarketing"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400 400"
      onMouseMove={(e) => {handleMove(e)}}
      onMouseOut={handleOut}
    >
      <defs>
        <linearGradient id="marketingGradient" gradientTransform="rotate(0)">
          <stop offset="5%" stopColor="var(--alpha)"></stop>
          <stop offset="95%" stopColor="var(--bravo)"></stop>
        </linearGradient>
      </defs>
      <g ref={refFlags}>
        <rect id="flag_x5F_right_x5F_pole" x="274.3" y="154.2" transform="matrix(0.9801 -0.1986 0.1986 0.9801 -40.1635 59.4171)" fill="#4D4D4D" width="3.6" height="151.5"></rect>
        <path id="flag_x5F_right" fill="var(--alpha)" d="M264.4,165.1l10.3,49.1c0,0,17.5-14.1,48.8-15.1s41.7-9.5,41.7-9.5s-0.9-36.7-6.6-43.5
          c0,0-18.4,5.2-43.8,2.1C289.4,145,264.4,165.1,264.4,165.1z"></path>
        <path ref={refFlag01} id="flag_x5F_mid_x5F_front" fill="url(#marketingGradient)" d="M141.1,37.8c23.5-11.6,87.6-43.9,120.5-36.9c3.2,17,20.7,109.3,22.9,120.2c-51.4-12-82,3-125.1,21.6C153.2,107.4,142.9,48.6,141.1,37.8z"></path>
        <rect id="flag_x5F_mid_x5F_pole" x="156.2" y="31.2" transform="matrix(0.9801 -0.1986 0.1986 0.9801 -23.3967 34.0992)" fill="#4D4D4D" width="4.2" height="205"></rect>
        <rect id="flag_x5F_left_x5F_pole" x="95.3" y="156.6" transform="matrix(0.9801 -0.1986 0.1986 0.9801 -44.2062 23.9152)" fill="#4D4D4D" width="3.6" height="151.5"></rect>
        <path id="flag_x5F_left" fill="var(--bravo)" d="M84.5,162.5c0,0,46.1-19.3,87.6-2.1c0,0-53.8,22.9-77.5,52.1L84.5,162.5z"></path>
        <g ref={refLogo} id="alopex_x5F_logo">
          <g>
            <path fill="#F5F5F5" d="M227.6,33.6l-19.5,26.2l18.5,3.4L227.6,33.6z M207.7,61.1l6.2,38.7l12.3-35.3L207.7,61.1z M206.5,61.3l-16.4,9l22.6,29.6
              L206.5,61.3z M179.3,41.8l10.2,27.3l16.2-9L179.3,41.8z M213.9,103.7l-25.3-33.2l-12-32.1l30.2,21L229,29.5l-1.1,34.6L213.9,103.7
              z"></path>
          </g>
        </g>
      </g>
      <g ref={refPeak} id="mountain">
        <polygon fill="#F2F2F2" points="189.5,205.8 165.8,254.9 260.9,242.7"></polygon>
        <polygon fill="#E6E6E6" points="260.9,242.7 289.9,276 165.8,254.9"></polygon>
        <polygon fill="#CCCCCC" points="189.5,205.8 144.6,236 165.8,254.9"></polygon>
        <polygon fill="#B3B3B3" points="144.6,236 114.3,272.7 165.8,254.9"></polygon>
        <polygon fill="#CCCCCC" points="165.8,254.9 114.3,339.1 289.9,276"></polygon>
        <polygon fill="#999999" points="165.8,254.9 114.3,339.1 114.3,272.7"></polygon>
        <polygon fill="#F2F2F2" points="289.9,276 362.5,339.1 224.5,368"></polygon>
        <polygon fill="#E6E6E6" points="114.3,339.1 224.5,368 289.9,276"></polygon>
        <polygon fill="#CCCCCC" points="114.3,339.1 54.8,356 224.5,368"></polygon>
        <polygon fill="#B3B3B3" points="114.3,272.7 54.8,356 114.3,339.1"></polygon>
        <polygon fill="#B3B3B3" points="54.8,356 0,400 224.5,368"></polygon>
        <polygon fill="#E6E6E6" points="362.5,339.1 400,400 224.5,368"></polygon>
        <polygon fill="#CCCCCC" points="0,400 400,400 224.5,368"></polygon>
      </g>
    </svg>
  )
}

export default Marketing