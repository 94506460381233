import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const Design = () => {
  const refDesignSVG = useRef()
  const refLaptop = useRef()
  const refMobile = useRef()
  const refTrees = useRef()
  const refMountains = useRef()

  useEffect(() => {
    /* gsap.set(refLaptop.current, {transformOrigin: '50% 50%', y: 0})
    gsap.from(refLaptop.current, {
      scrollTrigger: {
        trigger: refDesignSVG.current,
        start: 'top 75%',
        scrub: false,
        // marker: true
      },
      duration: 2,
      y: -75,
    }) */
    gsap.from(refMobile.current, {
      scrollTrigger: {
        trigger: refDesignSVG.current,
        start: 'top 75%',
        scrub: false,
        // marker: true
      },
      duration: 3,
      y: 75,
    })
  }, [])

  const getRange = (percent, min, max) => {
    return min + (max - min) * percent / 100;
  }

  const handleMove = (e) => {
    let xPos = (e.clientX - (refDesignSVG.current.getBoundingClientRect().left)) / refDesignSVG.current.getBoundingClientRect().width
    let yPos = (e.clientY - (refDesignSVG.current.getBoundingClientRect().top)) / refDesignSVG.current.getBoundingClientRect().height
    gsap.to(refLaptop.current, {
      x: getRange(xPos * 100, 10, -10),
      y: getRange(yPos * 100, 20, -20),
      ease: 'power1.out'
    })
    gsap.to(refMobile.current, {
      x: getRange(xPos * 100, -10, 10),
      y: getRange(yPos * 100, -5, 35),
      ease: 'power1.out'
    })
    gsap.to(refMountains.current, {
      y: getRange(yPos * 100, 15, 0),
      ease: 'power1.out'
    })
  }

  const handleOut = () => {
    gsap.to([refMobile.current, refLaptop.current], {
      x: 0,
      y: 0
    })
  }

  return (
    <svg
      ref={refDesignSVG}
      style={{overflow: 'visible'}}
      className="svgDesign"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400 400"
      onMouseMove={(e) => {handleMove(e)}}
      onMouseOut={handleOut}
    >
      <defs>
        <linearGradient id="designGradient" gradientTransform="rotate(-30)">
          <stop offset="5%" stopColor="var(--alpha)"></stop>
          <stop offset="95%" stopColor="var(--bravo)"></stop>
        </linearGradient>
        <clipPath id="SVGID_2_4">
          <rect id="SVGID_1_4" x="38.7" y="114.8" width="330" height="185.6"></rect>
        </clipPath>
        <clipPath id="SVGID_4_4">
          <rect id="SVGID_3_4" x="40.2" y="116.3" width="327" height="182.6"></rect>
        </clipPath>
      </defs>
      <g ref={refLaptop} id="back">
        <g>
          <g>
            <g>
              <g>
                <g clipPath="url(#SVGID_2_4)">
                  <polygon clipPath="url(#SVGID_4_4)" fill="url(#designGradient)" points="-51.1,207.7 203.7,-47.2 458.6,207.7 203.7,462.5"></polygon>
                </g>
                <path clipPath="url(#SVGID_2_4)" fill="#E6E6E6" d="M365.7,297.5h-324V117.8h324V297.5z M368.7,114.8h-330v185.6h330V114.8z"></path>
              </g>
            </g>
          </g>
          <g ref={refMountains}>
            <polygon fill="#F2F2F2" points="366.1,297 41.8,296.7 41.8,129.1 65.2,124.3 95.2,100 120,93.5 136.4,85.5 152.7,93.5 163,107.3 
              182.4,125.2 205.3,142.3 232.1,183.7 252.5,159 274.9,150.6 284,163.8 299.9,188.4 312.9,183.7 343.3,210.4 366.6,239.9"></polygon>
            <polygon fill="#E6E6E6" points="98.6,202.4 91.2,297.5 50.1,297.5"></polygon>
            <polygon fill="#E6E6E6" points="136.4,85.5 131.8,112.1 120,93.5"></polygon>
            <polygon fill="#E6E6E6" points="95.2,100 86.1,122.9 65.2,124.3"></polygon>
            <polygon fill="#E6E6E6" points="274.9,150.6 263.7,163.8 252.5,159"></polygon>
            <polygon fill="#E6E6E6" points="131.8,112.1 86.1,122.9 120,131.3"></polygon>
            <polygon fill="#E6E6E6" points="166.5,155.5 176.2,204.9 157.4,269"></polygon>
            <polygon fill="#E6E6E6" points="252.5,177.3 232.1,183.7 252.5,159"></polygon>
            <polygon fill="#E6E6E6" points="166.5,155.5 128.2,242.4 157.4,269"></polygon>
            <polygon fill="#E6E6E6" points="120,131.3 98.6,202.4 80.2,160.5"></polygon>
          </g>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="243.7" y1="297.8" x2="241.3" y2="297.1"></line>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="238.9" y1="297.8" x2="241.3" y2="297.1"></line>
        </g>
        <g ref={refTrees}>
          <g id="trees_x5F_left">
            <rect x="210.6" y="290.8" fill="#9E9FA0" width="4.8" height="8.6"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="215.4" y1="293.1" x2="213" y2="292.4"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="210.6" y1="293.1" x2="213" y2="292.4"></line>
            <polygon fill="#B5B7B7" points="213,277.4 221.9,280.6 231.4,297.4 213,292.4"></polygon>
            <polygon fill="#A2A4A5" points="213,277.4 204.2,280.6 194.7,297.4 213,292.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="221.9" y1="280.6" x2="213" y2="277.4"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="213" y1="277.4" x2="204.2" y2="280.6"></line>
            <polygon fill="#CCCCCC" points="213,261.7 220.9,264.7 228.4,282.8 213,277.4"></polygon>
            <polygon fill="#B5B7B7" points="213,261.7 205.1,264.7 197.7,282.8 213,277.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="213" y1="261.7" x2="220.9" y2="264.7"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="213" y1="261.7" x2="205.1" y2="264.7"></line>
            <polygon fill="#E6E6E6" points="213,238.4 224.2,265.9 213,261.7"></polygon>
            <polygon fill="#CCCCCC" points="213,238.4 201.8,265.9 213,261.7"></polygon>
            <rect x="189.3" y="292.3" fill="#9E9FA0" width="3.8" height="6.8"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="193.1" y1="294.1" x2="191.2" y2="293.6"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="189.3" y1="294.1" x2="191.2" y2="293.6"></line>
            <polygon fill="#B5B7B7" points="191.2,281.8 198.1,284.2 205.6,297.5 191.2,293.6"></polygon>
            <polygon fill="#A2A4A5" points="191.2,281.8 184.2,284.2 176.8,297.5 191.2,293.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="198.1" y1="284.2" x2="191.2" y2="281.8"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="191.2" y1="281.8" x2="184.2" y2="284.2"></line>
            <polygon fill="#CCCCCC" points="191.2,269.5 197.4,271.8 203.2,286 191.2,281.8"></polygon>
            <polygon fill="#B5B7B7" points="191.2,269.5 185,271.8 179.1,286 191.2,281.8"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="191.2" y1="269.5" x2="197.4" y2="271.8"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="191.2" y1="269.5" x2="185" y2="271.8"></line>
            <polygon fill="#E6E6E6" points="191.2,251.2 200,272.7 191.2,269.5"></polygon>
            <polygon fill="#CCCCCC" points="191.2,251.2 182.4,272.7 191.2,269.5"></polygon>
            <rect x="173.1" y="293.8" fill="#9E9FA0" width="3" height="5.4"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="176.1" y1="295.3" x2="174.6" y2="294.8"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="173.1" y1="295.3" x2="174.6" y2="294.8"></line>
            <polygon fill="#B5B7B7" points="174.6,285.4 180.2,287.3 186.2,298 174.6,294.8"></polygon>
            <polygon fill="#A2A4A5" points="174.6,285.4 169,287.3 163,298 174.6,294.8"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="180.2" y1="287.3" x2="174.6" y2="285.4"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="174.6" y1="285.4" x2="169" y2="287.3"></line>
            <polygon fill="#CCCCCC" points="174.6,275.5 179.6,277.3 184.3,288.8 174.6,285.4"></polygon>
            <polygon fill="#B5B7B7" points="174.6,275.5 169.6,277.3 164.9,288.8 174.6,285.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="174.6" y1="275.5" x2="179.6" y2="277.3"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="174.6" y1="275.5" x2="169.6" y2="277.3"></line>
            <polygon fill="#E6E6E6" points="174.6,260.7 181.7,278.1 174.6,275.5"></polygon>
            <polygon fill="#CCCCCC" points="174.6,260.7 167.5,278.1 174.6,275.5"></polygon>
          </g>
          <g id="trees_x5F_middle">
            <rect x="248.9" y="286.2" fill="#9E9FA0" width="5.8" height="10.5"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="254.7" y1="288.9" x2="251.8" y2="288.2"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="248.9" y1="288.9" x2="251.8" y2="288.2"></line>
            <polygon fill="#B5B7B7" points="251.8,269.9 262.6,273.7 274.1,294.2 251.8,288.2"></polygon>
            <polygon fill="#A2A4A5" points="251.8,269.9 241,273.7 229.5,294.2 251.8,288.2"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="262.6" y1="273.7" x2="251.8" y2="269.9"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="251.8" y1="269.9" x2="241" y2="273.7"></line>
            <polygon fill="#CCCCCC" points="251.8,250.9 261.4,254.4 270.4,276.5 251.8,269.9"></polygon>
            <polygon fill="#B5B7B7" points="251.8,250.9 242.2,254.4 233.1,276.5 251.8,269.9"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="251.8" y1="250.9" x2="261.4" y2="254.4"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="251.8" y1="250.9" x2="242.2" y2="254.4"></line>
            <polygon fill="#E6E6E6" points="251.8,222.5 265.4,255.9 251.8,250.9"></polygon>
            <polygon fill="#CCCCCC" points="251.8,222.5 238.2,255.9 251.8,250.9"></polygon>
            <rect x="238.9" y="295.5" fill="#9E9FA0" width="4.8" height="8.6"></rect>
            <polygon fill="#B5B7B7" points="241.3,282.1 250.2,285.2 259.6,302.1 241.3,297.1"></polygon>
            <polygon fill="#A2A4A5" points="241.3,282.1 232.4,285.2 222.9,302.1 241.3,297.1"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="250.2" y1="285.2" x2="241.3" y2="282.1"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="241.3" y1="282.1" x2="232.4" y2="285.2"></line>
            <polygon fill="#CCCCCC" points="241.3,266.4 249.2,269.4 256.6,287.5 241.3,282.1"></polygon>
            <polygon fill="#B5B7B7" points="241.3,266.4 233.4,269.4 225.9,287.5 241.3,282.1"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="241.3" y1="266.4" x2="249.2" y2="269.4"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="241.3" y1="266.4" x2="233.4" y2="269.4"></line>
            <polygon fill="#E6E6E6" points="241.3,243.1 252.5,270.6 241.3,266.4"></polygon>
            <polygon fill="#CCCCCC" points="241.3,243.1 230.1,270.6 241.3,266.4"></polygon>
            <polygon fill="#B5B7B7" points="270.4,288.8 277.4,291.3 284.8,304.5 270.4,300.6"></polygon>
            <polygon fill="#A2A4A5" points="270.4,288.8 263.5,291.3 256,304.5 270.4,300.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="277.4" y1="291.3" x2="270.4" y2="288.8"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="270.4" y1="288.8" x2="263.5" y2="291.3"></line>
            <polygon fill="#CCCCCC" points="270.4,276.5 276.6,278.8 282.5,293.1 270.4,288.8"></polygon>
            <polygon fill="#B5B7B7" points="270.4,276.5 264.2,278.8 258.4,293.1 270.4,288.8"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="270.4" y1="276.5" x2="276.6" y2="278.8"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="270.4" y1="276.5" x2="264.2" y2="278.8"></line>
            <polygon fill="#E6E6E6" points="270.4,258.2 279.2,279.8 270.4,276.5"></polygon>
            <polygon fill="#CCCCCC" points="270.4,258.2 261.6,279.8 270.4,276.5"></polygon>
          </g>
          <g id="trees_x5F_right">
            <rect x="314.3" y="276.5" fill="#9E9FA0" width="6.6" height="11.8"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="320.8" y1="279.5" x2="317.6" y2="278.6"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="314.3" y1="279.5" x2="317.6" y2="278.6"></line>
            <polygon fill="#B5B7B7" points="317.6,258.1 329.7,262.4 342.6,285.4 317.6,278.6"></polygon>
            <polygon fill="#A2A4A5" points="317.6,258.1 305.4,262.4 292.5,285.4 317.6,278.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="329.7" y1="262.4" x2="317.6" y2="258.1"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="317.6" y1="258.1" x2="305.4" y2="262.4"></line>
            <polygon fill="#CCCCCC" points="317.6,236.7 328.3,240.7 338.5,265.5 317.6,258.1"></polygon>
            <polygon fill="#B5B7B7" points="317.6,236.7 306.8,240.7 296.6,265.5 317.6,258.1"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="317.6" y1="236.7" x2="328.3" y2="240.7"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="317.6" y1="236.7" x2="306.8" y2="240.7"></line>
            <polygon fill="#E6E6E6" points="317.6,204.8 332.9,242.4 317.6,236.7"></polygon>
            <polygon fill="#CCCCCC" points="317.6,204.8 302.3,242.4 317.6,236.7"></polygon>
            <rect x="293.3" y="274.4" fill="#9E9FA0" width="6.6" height="11.8"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="299.9" y1="277.5" x2="296.6" y2="276.6"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="293.3" y1="277.5" x2="296.6" y2="276.6"></line>
            <polygon fill="#B5B7B7" points="296.6,256.1 308.7,260.4 321.7,283.4 296.6,276.6"></polygon>
            <polygon fill="#A2A4A5" points="296.6,256.1 284.5,260.4 271.5,283.4 296.6,276.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="308.7" y1="260.4" x2="296.6" y2="256.1"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="296.6" y1="256.1" x2="284.5" y2="260.4"></line>
            <polygon fill="#CCCCCC" points="296.6,234.7 307.4,238.7 317.6,263.5 296.6,256.1"></polygon>
            <polygon fill="#B5B7B7" points="296.6,234.7 285.8,238.7 275.6,263.5 296.6,256.1"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="296.6" y1="234.7" x2="307.4" y2="238.7"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="296.6" y1="234.7" x2="285.8" y2="238.7"></line>
            <polygon fill="#E6E6E6" points="296.6,202.8 311.9,240.3 296.6,234.7"></polygon>
            <polygon fill="#CCCCCC" points="296.6,202.8 281.3,240.3 296.6,234.7"></polygon>
            <rect x="331.4" y="286.9" fill="#9E9FA0" width="5.8" height="10.5"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="337.2" y1="289.7" x2="334.3" y2="288.9"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="331.4" y1="289.7" x2="334.3" y2="288.9"></line>
            <polygon fill="#ABADAE" points="334.3,270.6 345.1,274.4 356.6,294.9 334.3,288.9"></polygon>
            <polygon fill="#A2A4A5" points="334.3,270.6 323.5,274.4 312,294.9 334.3,288.9"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="345.1" y1="274.4" x2="334.3" y2="270.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="334.3" y1="270.6" x2="323.5" y2="274.4"></line>
            <polygon fill="#CCCCCC" points="334.3,251.6 343.9,255.1 353,277.2 334.3,270.6"></polygon>
            <polygon fill="#B5B7B7" points="334.3,251.6 324.7,255.1 315.7,277.2 334.3,270.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="334.3" y1="251.6" x2="343.9" y2="255.1"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="334.3" y1="251.6" x2="324.7" y2="255.1"></line>
            <polygon fill="#E6E6E6" points="334.3,223.2 347.9,256.6 334.3,251.6"></polygon>
            <polygon fill="#CCCCCC" points="334.3,223.2 320.7,256.6 334.3,251.6"></polygon>
            <polygon fill="#B5B7B7" points="300.2,286.6 307.2,289.1 314.6,302.3 300.2,298.4"></polygon>
            <polygon fill="#A2A4A5" points="300.2,286.6 293.3,289.1 285.8,302.3 300.2,298.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="307.2" y1="289.1" x2="300.2" y2="286.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="300.2" y1="286.6" x2="293.3" y2="289.1"></line>
            <polygon fill="#CCCCCC" points="300.2,274.3 306.4,276.6 312.3,290.9 300.2,286.6"></polygon>
            <polygon fill="#B5B7B7" points="300.2,274.3 294.1,276.6 288.2,290.9 300.2,286.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="300.2" y1="274.3" x2="306.4" y2="276.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="300.2" y1="274.3" x2="294.1" y2="276.6"></line>
            <polygon fill="#E6E6E6" points="300.2,256 309,277.6 300.2,274.3"></polygon>
            <polygon fill="#CCCCCC" points="300.2,256 291.5,277.6 300.2,274.3"></polygon>
            <polygon fill="#B5B7B7" points="286.9,290.2 293.9,292.7 301.3,305.9 286.9,302"></polygon>
            <polygon fill="#A2A4A5" points="286.9,290.2 280,292.7 272.5,305.9 286.9,302"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="293.9" y1="292.7" x2="286.9" y2="290.2"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="286.9" y1="290.2" x2="280" y2="292.7"></line>
            <polygon fill="#CCCCCC" points="286.9,277.9 293.1,280.2 299,294.5 286.9,290.2"></polygon>
            <polygon fill="#B5B7B7" points="286.9,277.9 280.8,280.2 274.9,294.5 286.9,290.2"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="286.9" y1="277.9" x2="293.1" y2="280.2"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="286.9" y1="277.9" x2="280.8" y2="280.2"></line>
            <polygon fill="#E6E6E6" points="286.9,259.6 295.7,281.2 286.9,277.9"></polygon>
            <polygon fill="#CCCCCC" points="286.9,259.6 278.2,281.2 286.9,277.9"></polygon>
            <rect x="359.2" y="289.8" fill="#9E9FA0" width="4.8" height="8.6"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="364" y1="292" x2="361.6" y2="291.4"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="359.2" y1="292" x2="361.6" y2="291.4"></line>
            <polygon fill="#B5B7B7" points="361.6,276.4 370.5,279.5 380,296.4 361.6,291.4"></polygon>
            <polygon fill="#A2A4A5" points="361.6,276.4 352.8,279.5 343.3,296.4 361.6,291.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="370.5" y1="279.5" x2="361.6" y2="276.4"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.6" y1="276.4" x2="352.8" y2="279.5"></line>
            <polygon fill="#CCCCCC" points="361.6,260.7 369.5,263.6 377,281.8 361.6,276.4"></polygon>
            <polygon fill="#B5B7B7" points="361.6,260.7 353.8,263.6 346.3,281.8 361.6,276.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.6" y1="260.7" x2="369.5" y2="263.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="361.6" y1="260.7" x2="353.8" y2="263.6"></line>
            <polygon fill="#E6E6E6" points="361.6,237.4 372.8,264.9 361.6,260.7"></polygon>
            <polygon fill="#CCCCCC" points="361.6,237.4 350.5,264.9 361.6,260.7"></polygon>
            <rect x="342.5" y="293.7" fill="#9E9FA0" width="4.8" height="8.6"></rect>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="347.3" y1="295.9" x2="344.9" y2="295.3"></line>
            <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="342.5" y1="295.9" x2="344.9" y2="295.3"></line>
            <polygon fill="#B5B7B7" points="344.9,280.2 353.7,283.4 363.2,300.2 344.9,295.3"></polygon>
            <polygon fill="#A2A4A5" points="344.9,280.2 336,283.4 326.5,300.2 344.9,295.3"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="353.7" y1="283.4" x2="344.9" y2="280.2"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="344.9" y1="280.2" x2="336" y2="283.4"></line>
            <polygon fill="#CCCCCC" points="344.9,264.6 352.7,267.5 360.2,285.7 344.9,280.2"></polygon>
            <polygon fill="#B5B7B7" points="344.9,264.6 337,267.5 329.5,285.7 344.9,280.2"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="344.9" y1="264.6" x2="352.7" y2="267.5"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="344.9" y1="264.6" x2="337" y2="267.5"></line>
            <polygon fill="#E6E6E6" points="344.9,241.2 356,268.7 344.9,264.6"></polygon>
            <polygon fill="#CCCCCC" points="344.9,241.2 333.7,268.7 344.9,264.6"></polygon>
            <rect x="329.3" y="296.2" fill="#9E9FA0" width="3.8" height="6.8"></rect>
            <polygon fill="#B5B7B7" points="331.2,285.6 338.2,288.1 345.6,301.3 331.2,297.4"></polygon>
            <polygon fill="#A2A4A5" points="331.2,285.6 324.3,288.1 316.8,301.3 331.2,297.4"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="338.2" y1="288.1" x2="331.2" y2="285.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="331.2" y1="285.6" x2="324.3" y2="288.1"></line>
            <polygon fill="#CCCCCC" points="331.2,273.3 337.4,275.6 343.3,289.9 331.2,285.6"></polygon>
            <polygon fill="#B5B7B7" points="331.2,273.3 325,275.6 319.2,289.9 331.2,285.6"></polygon>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="331.2" y1="273.3" x2="337.4" y2="275.6"></line>
            <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="331.2" y1="273.3" x2="325" y2="275.6"></line>
            <polygon fill="#E6E6E6" points="331.2,255 340,276.6 331.2,273.3"></polygon>
            <polygon fill="#CCCCCC" points="331.2,255 322.4,276.6 331.2,273.3"></polygon>
          </g>
        </g>
        <g>
          <path fill="#CCCCCC" d="M391.2,311.1h-375c-4.8,0-8.8-3.9-8.8-8.8v-1.9H400v1.9C400,307.2,396.1,311.1,391.2,311.1"></path>
          <rect x="38.7" y="296.9" fill="#E6E6E6" width="329.7" height="3.6"></rect>
        </g>
      </g>
      <g ref={refMobile} id="front">
        <path fill="#E6E6E6" d="M68.4,314.5H3.8c-2.1,0-3.8-1.7-3.8-3.8V187.4c0-2.1,1.7-3.8,3.8-3.8h64.6c2.1,0,3.8,1.7,3.8,3.8v123.3
          C72.2,312.8,70.5,314.5,68.4,314.5"></path>
        <g>
          <g>
            <defs>
              <clipPath id="SVGID_7_4">
                <rect x="3" y="186.6" width="66.3" height="124.9"></rect>
              </clipPath>
            </defs>
            <g clipPath="url(#SVGID_7_4)">
              <defs>
                <clipPath id="SVGID_9_4">
                  <path d="M66.9,311.5H5.3c-1.3,0-2.4-1.1-2.4-2.4V189c0-1.3,1.1-2.4,2.4-2.4h61.5c1.3,0,2.4,1.1,2.4,2.4v120.2
                    C69.3,310.5,68.2,311.5,66.9,311.5"></path>
                </clipPath>
              </defs>
              <polygon clipPath="url(#SVGID_9_4)" fill="url(#designGradient)" points="-59.5,249.1 36.1,153.5 131.7,249.1 36.1,344.7	"></polygon>

            </g>

          </g>
        </g>
        <path fill="#E6E6E6" d="M49.8,190.2H22.5c-0.5,0-0.9-0.4-0.9-0.9v-2.7h29.1v2.7C50.7,189.8,50.3,190.2,49.8,190.2"></path>
        <path fill="#E6E6E6" d="M21.1,186.6c0.3,0,0.5,0.2,0.5,0.5v-0.5H21.1z"></path>
        <path fill="#E6E6E6" d="M51.2,186.6c-0.3,0-0.5,0.2-0.5,0.5v-0.5H51.2z"></path>
        <line fill="none" stroke="#B3B3B3" strokeWidth="0.75" strokeLinecap="round" strokeMiterlimit="10" x1="32.6" y1="188.4" x2="39.7" y2="188.4"></line>
        <g>
          <rect x="41.8" y="296.7" fill="#9E9FA0" width="8.3" height="14.9"></rect>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="50.1" y1="300.5" x2="45.9" y2="299.4"></line>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="41.8" y1="300.5" x2="45.9" y2="299.4"></line>
          <polygon fill="#B5B7B7" points="45.9,273.5 61.2,278.9 77.6,308 45.9,299.4"></polygon>
          <polygon fill="#A2A4A5" points="45.9,273.5 30.6,278.9 14.3,308 45.9,299.4"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="61.2" y1="278.9" x2="45.9" y2="273.5"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="45.9" y1="273.5" x2="30.6" y2="278.9"></line>
          <polygon fill="#CCCCCC" points="45.9,246.5 59.5,251.6 72.4,282.9 45.9,273.5"></polygon>
          <polygon fill="#B5B7B7" points="45.9,246.5 32.3,251.6 19.5,282.9 45.9,273.5"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="45.9" y1="246.5" x2="59.5" y2="251.6"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="45.9" y1="246.5" x2="32.3" y2="251.6"></line>
          <polygon fill="#E6E6E6" points="45.9,206.3 65.2,253.7 45.9,246.5"></polygon>
          <polygon fill="#CCCCCC" points="45.9,206.3 26.7,253.7 45.9,246.5"></polygon>
          <rect x="26.1" y="302.9" fill="#9E9FA0" width="4.8" height="8.6"></rect>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="30.9" y1="305.1" x2="28.5" y2="304.5"></line>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="26.1" y1="305.1" x2="28.5" y2="304.5"></line>
          <polygon fill="#B5B7B7" points="28.5,289.5 37.4,292.6 46.8,309.5 28.5,304.5"></polygon>
          <polygon fill="#A2A4A5" points="28.5,289.5 19.6,292.6 10.1,309.5 28.5,304.5"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="37.4" y1="292.6" x2="28.5" y2="289.5"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="28.5" y1="289.5" x2="19.6" y2="292.6"></line>
          <polygon fill="#CCCCCC" points="28.5,273.8 36.4,276.7 43.8,294.9 28.5,289.5"></polygon>
          <polygon fill="#B5B7B7" points="28.5,273.8 20.6,276.7 13.1,294.9 28.5,289.5"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="28.5" y1="273.8" x2="36.4" y2="276.7"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="28.5" y1="273.8" x2="20.6" y2="276.7"></line>
          <polygon fill="#E6E6E6" points="28.5,250.4 39.7,278 28.5,273.8"></polygon>
          <polygon fill="#CCCCCC" points="28.5,250.4 17.3,278 28.5,273.8"></polygon>
          <rect x="13.8" y="306.1" fill="#9E9FA0" width="3" height="5.5"></rect>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="16.8" y1="307.5" x2="15.3" y2="307.1"></line>
          <line fill="none" stroke="#808080" strokeWidth="0.25" strokeMiterlimit="10" x1="13.8" y1="307.5" x2="15.3" y2="307.1"></line>
          <polygon fill="#B5B7B7" points="15.3,297.6 20.9,299.6 26.9,310.2 15.3,307.1"></polygon>
          <polygon fill="#A2A4A5" points="15.3,297.6 9.7,299.6 3.7,310.2 15.3,307.1"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="20.9" y1="299.6" x2="15.3" y2="297.6"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="15.3" y1="297.6" x2="9.7" y2="299.6"></line>
          <polygon fill="#CCCCCC" points="15.3,287.7 20.3,289.6 25,301 15.3,297.6"></polygon>
          <polygon fill="#B5B7B7" points="15.3,287.7 10.3,289.6 5.6,301 15.3,297.6"></polygon>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="15.3" y1="287.7" x2="20.3" y2="289.6"></line>
          <line fill="none" stroke="#9E9FA0" strokeWidth="0.25" strokeMiterlimit="10" x1="15.3" y1="287.7" x2="10.3" y2="289.6"></line>
          <polygon fill="#E6E6E6" points="15.3,273 22.4,290.3 15.3,287.7"></polygon>
          <polygon fill="#CCCCCC" points="15.3,273 8.3,290.3 15.3,287.7"></polygon>
        </g>
      </g>
    </svg>
  )
}

export default Design